
.login-form-style {
    width: 400px;
    height: 240px;
    align-items: center;
    padding: 15px;
    text-align: center;
}

@media screen and ( max-width: 500px ) {
    .login-form-style {
        width: 300px;
        height: 240px;
        align-items: center;
        padding: 15px;
        text-align: center;
    }
}

.component-position {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    height: 80vh;
}