.main {
    padding-left: 250px!important;
}

.input-size {
    height: 32px!important;
}

.code-size {
    width: 32px!important;
}

@media screen and ( max-width: 768px ) {
    .main {
        padding-left: 0.2rem!important;
    }
}

/* navbar styles */
@media screen and ( min-width: 576px ) {
    .navbar-under-left {
        width: 250px;
        border-right: 1px solid #dee2e6;
    }
}

@media screen and ( max-width: 576px ) {
    .navbar-under-left {
        border-right: 1px solid rgba(0, 0, 0, 0);
    }
}

.logout-btn {
    width: 81px;
    font-size: 12.5px!important;
}

/* sidebar styles */
.sidebar {
    position: fixed!important;
    z-index: 100;
    border-right: 1px #000000;
}

.sidebar-content-button {
    text-align: start!important;
    padding-left: 30px!important;
    font-size: 11pt!important;
}

.accordion-button {
    box-shadow: none !important;
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
    background-color: white!important;
    color: black!important;
    font-size: 13pt!important;
}

.nostyle .accordion-button {
    height: 37px;
    background-color: white!important;
    color: black!important;
}

.nostyle .accordion-button::after {
    display: none!important;
}

.bg-gray {
    background-color: #d2f4ea!important;
}

/* table styles */

.table-wrapper {
    max-height: calc((100vh - 120px) * 0.84);
    overflow: auto;
}

.product-table-wrapper {
    max-height: 481px;
    overflow: auto;
}

.table-condensed {
    width: 100%;
    font-size: 13px;
}

.table-condensed th {
    height: 30px;
}

.table-condensed td {
    height: 30px;
}

.table-condensed input {
    font-size: 12px;
}

.table-condensed input[type=checkbox] {
    font-size: 15px;
}

.sticky-head th {
    background-color: #FFFFFF!important;
    position: -webkit-sticky;
    position: sticky;
    z-index: 2;
    top: 0;
}

.sticky-head th:after,
.sticky-head th:before {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
}

.sticky-head th:before{
    top: -1px;
    right: -2px;
    border-top: 1px solid #dddddd;
}

.sticky-head th:after {
    bottom: -2px;

    border-bottom: 2px solid black;
}

/* scrollbar styles */
.scrollbar::-webkit-scrollbar {
    width: 14px;
}

.scrollbar::-webkit-scrollbar-thumb {
    background-color: rgba(200, 200, 200, 0.4);
    border: 4px solid rgba(0, 0, 0, 0);
    border-radius: 10px;
    background-clip: padding-box;
    transition: background-color .5s;
}

.scrollbar:hover::-webkit-scrollbar-thumb {
    background-color: rgba(170, 170, 170, 0.4)
}

.scrollbar::-webkit-scrollbar-track {
    background-color: transparent;
}

.button-footer {
    margin-left: 250px;
}

@media screen and (max-width: 768px) {
    .button-footer {
        margin-left: 0;
    }
}

.button-fixed-size {
    width: 100px;
}

/* SETTINGS styles */
.password-addon {
    display: block!important;
    white-space: nowrap;
    min-width: 130px;
    text-align: center;
    box-sizing: border-box;
}