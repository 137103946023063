
.focused {
    background-color: #2c3034;
}

.row-disabled {
    color: #b0b0b0
}

.category-row-selected {
    background-color: #e0e0e0
}