
.input-field {
    height: 25px;
}

.addonSize {
    display: block!important;
    white-space: nowrap;
    min-width: 80px;
    text-align: center;
    box-sizing: border-box;
}

.code-row-selected {
    background-color: #d0d0d0
}