
.cus-addon-size {
    display: block!important;
    white-space: nowrap;
    min-width: 120px;
    text-align: center;
    box-sizing: border-box;
}

.cus-main {
    margin-left: 250px;
}