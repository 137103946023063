
.image-container {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 360px;
}

.login-form-position {
    margin-top: 100px;
}

.login-image {
    width: 400px;
    height: 240px;
}

@media screen and ( max-width: 576px ) {
    .login-image {
        width: 300px;
        height: 180px;
    }

    .login-form-position {
        margin-top: 0;
    }

    .image-container {
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 120px;
    }
}