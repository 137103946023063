
span {
    background: #6c757d;
}

.addonSize {
    display: block!important;
    white-space: nowrap;
    min-width: 120px;
    text-align: center;
    box-sizing: border-box;
}

.zip {
    display: block!important;
    white-space: nowrap;
    min-width: 70px;
    text-align: center;
    box-sizing: border-box;
}

.saup_comp {
    min-width: 120px;
}

.sign-button {
    color: #212529;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
}

/* registration */
@media screen and (max-width: 568px) {
    .regist-label {
        font-size: 13px;
    }
}